<template>
    <div>
        <header-title :title="title"></header-title>
        <rs-row>
            <!-- 角色基本信息 -->
            <rs-form :model="ruleForm" :show-message="false" size="small" label-position="right" status-icon ref="ruleForm" label-width="156px" class="demo-ruleForm form-70">
                <rs-col :span="12">
                    <rs-form-item
                        label="角色名称"
                        prop="roleName"
                        :rules="[
                        { required: true, message: '请输入角色名称', trigger: 'blur' },
                        { max:10, message: '最大长度为10', trigger: 'blur' },
                        ]"
                    >
                        <rs-input type="text" :disabled="readonly" v-model="ruleForm.roleName" autocomplete="off"></rs-input>
                    </rs-form-item>
                </rs-col>
                <rs-col :span="12">
                    <rs-form-item
                        label="角色状态"
                        prop="status"
                        :rules="[
                        { required: true, message: '请选择角色状态', trigger: 'blur' },
                        ]"
                    >
                        <rs-select
                            :disabled="readonly"
                            v-model="ruleForm.status">
                            <rs-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </rs-option>
                        </rs-select>
                    </rs-form-item>
                </rs-col>
                <rs-col :span="24">
                    <rs-form-item
                        label="角色描述">
                        <rs-input show-word-limitp type="textarea" maxlength="100" :rows="5" :disabled="readonly" v-model="ruleForm.remark" autocomplete="off"></rs-input>
                    </rs-form-item>
                </rs-col>
                <rs-col :span="24">
                    <rs-form-item
                        label="配置权限"
                    >
                        <auth @authData="authData"></auth>
                    </rs-form-item>
                </rs-col>
            </rs-form>
        </rs-row>
        <!-- 当前用户 -->
        <div v-if="type === 'view'" style="margin:20px 0;">
            <rs-table
                :data="tableData"
                highlight-current-row
                stripe
                style="width: 100%">
                <rs-table-column
                    prop="userCode"
                    label="账号">
                </rs-table-column>
                <rs-table-column
                    prop="userName"
                    label="姓名">
                </rs-table-column>
                <rs-table-column
                    prop="fullDeptName"
                    label="组织">
                </rs-table-column>
            </rs-table>
        </div>
        <div class="edit-form button-box" style="text-align: right">
            <rs-button size="small"  @click="jumpBack(false)">取消</rs-button>
            <rs-button size="small" @click="save" type="primary" :loading="saveLoading">保存</rs-button>
        </div>
    </div>
</template>

<script>
    import Http from '@/api/api'
    import auth from '../authDetail'
    import headerTitle from '@/components/header'
    export default {
        name: "roleManageDetail",
        components: { auth, headerTitle },
        // props:['id','type'],
        data(){
            return{
                title: '新建角色',
                options:[{
                    value: false,
                    label:'已停用'
                },{
                    value: true,
                    label:'已启用'
                }],
                ruleForm: {
                    status:true,
                    roleName: '',
                    remark: '',
                    pathAddPrivilegeVos: null
                },
                treeData:'',
                tableData:[],
                defaultProps:{
                    label:'resourceName',
                    children:'childResourceVoList'
                },
                checklist:[],
                loading:false,
                parentOptions:[],
                list: [],
                status:'',
                readonly:false,
                saveLoading:false,
                checkAllTreeData: false,
                checkListData: []
            }
        },
        created(){
            this.type = this.$route.params.type
            this.id = this.$route.query.id
            if(this.type == 'create'){
                this.title = '新建角色'
            } else if(this.type == 'view'){
                this.getDetail(this.id)
                this.readonly = true
                this.title = '角色详情'
            }else{
                this.title = '编辑角色'
                this.getDetail(this.id)
                this.readonly = false
            }
        },
        methods:{
            getDetail(id){
                let params = {
                    operationId: id,
                    applicationId: 1,
                    operationType: 1
                }
                Http.findRoleDetail(params).then((res) => {
                    this.checklist = []
                    this.treeData = res.data.data.resourceVo
                    this.ruleForm = res.data.data
                    let selectTree = []
                    selectTree = this.handleCheckTree(res.data.data.applicationResourceVo.resourceVo,selectTree)
                    let form = {
                        operationId: id,
                        operationType: 1,
                        applicationPrivilegeVoList: []
                    }
                    let obj = {}
                    obj = {
                        applicationId: 1,
                        privilegeVoList: []
                    }
                    obj.privilegeVoList.push({
                        accessId:1,accessType: 0, masterId: 1*id, operationType: 2
                    })
                    selectTree.forEach(item=>{
                        obj.privilegeVoList.push({
                            accessId:1*item.id,accessType: 1, masterId: 1*id, operationType: 2
                        })
                    })
                    form.applicationPrivilegeVoList.push(obj)
                    this.ruleForm.pathAddPrivilegeVos = form
                    console.log(this.ruleForm)
                })
            },
            handleCheckTree(data, select) {
                if(data.choiceFlag) select.push(data)
                if(data.childResourceVoList&&data.childResourceVoList.length>0) {
                    data.childResourceVoList.map(i => this.handleCheckTree(i, select))
                }
                return select
            },
            authData(val) {
                this.ruleForm.pathAddPrivilegeVos = val
            },
            save(){
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        this.saveLoading = true
                        console.log(this.ruleForm, 'this.ruleForm')
                        Http.addRole(this.ruleForm).then(res=>{
                            if(res.data.code === 200) {
                                this.saveLoading = false
                                this.$message.success('操作成功')
                                this.jumpBack()
                            }
                       }).catch(err=>{
                           this.saveLoading = false
                       })
                    } 
                });
            },
            jumpBack(flag){
                // this.$emit('cancel',flag)
                // this.$closeTabSwitch(flag)
                this.$router.back()
            },
            jumpUrl(){
                let url = `/roleManageDetail/edit?id=${this.id}`
                this.$openTabSwitch('角色详情',url)
            },
        },

    }
</script>

<style scoped>
</style>
